import * as Dom from "Everlaw/Dom";
import dojo_topic = require("dojo/topic");
import * as QueryDialog from "Everlaw/UI/QueryDialog";
import { onSuperuserPage } from "Everlaw/Util";
import * as Widget from "Everlaw/UI/Widget";
import {
    AuthPromptMessage,
    NEED_AUTH,
    NEED_FEDERAL_TERMS_OF_USE_ACK,
    NEED_MFA,
    NEED_TERMS_OF_USE_ACK,
} from "Everlaw/Rest/RestAuthMessages";
import { enableLogoutPoll, Response } from "Everlaw/Rest/Rest";
import * as Rest from "Everlaw/Rest/Rest";
import { getActiveRecommendation } from "Everlaw/SmartOnboarding/RecommendationSharedVariables";

/*
This file exists for its side effects and has no exports!

For this to work, this file must be imported at least once on any given page on the platform.
Currently, this is done in `HeadHeaderInit.ts`.

This file sets up the reauth prompters that get triggered when a REST call is made when the user
needs to reauthenticate or acknowledge some prompt.

The content of this file used to be in Rest.ts, but it was moved to a separate file to avoid
importing dependencies in Rest.ts that would fail strict TypeScript checks.
 */

interface ReauthParams {
    authMessage: AuthPromptMessage;
    dialogTitle: string;
    dialogPrompt: string;
    makeDialogBody: (resp: Response, cb: () => void) => Promise<Widget>;
}

class ReauthPrompter {
    readonly authMessage: AuthPromptMessage;
    private widget: Widget;
    private dialog: QueryDialog | null;

    constructor(
        params: ReauthParams,
        private retryCallback: () => void,
        private failCallback: () => void,
        resp: Response,
    ) {
        // When we're making a Reauth Dialog, it's important to close any floating popups
        dojo_topic.publish("close-floating-panels");
        this.authMessage = params.authMessage;
        const body = Dom.div();
        this.dialog = QueryDialog.create({
            title: params.dialogTitle,
            prompt: params.dialogPrompt,
            classes: "reauth-prompter-dialog",
            body: body,
            onSubmit: () => {
                // this should never happen because the buttons are hidden
                return false;
            },
            onHide: () => {
                // When we've hidden the dialog, make sure to destroy everything and
                // If this is after a succesful authentication, any callbacks should
                // already have been called/discarded.
                this.destroy();
            },
            closable: false,
        });
        params
            .makeDialogBody(resp, () => this.success())
            .then((widget) => {
                this.widget = widget;
                Dom.place(this.widget, body);
                this.widget.focus();
            });
        this.dialog.hideLowerDiv(); // widgets should provide their own button
        this.dialog.show();
    }

    destroy() {
        // If we still have callbacks configured, make sure to fail!
        this.fail();
    }

    isActive() {
        return this.dialog && this.dialog.isOpen();
    }

    private success() {
        this.destroyAndExecute(this.retryCallback);
    }

    private fail() {
        this.destroyAndExecute(this.failCallback);
    }

    private destroyAndExecute(callback: () => void) {
        if (this.dialog) {
            this.dialog.hide();
            this.dialog = null;
        }
        this.widget && this.widget.destroy();
        this.retryCallback = () => {};
        this.failCallback = () => {};
        // Fire off the callback asynchronously
        if (callback) {
            setTimeout(callback, 0);
        }
    }
}

const loginPrompter: ReauthParams = {
    authMessage: NEED_AUTH,
    dialogTitle: "Log In",
    dialogPrompt:
        "You have been logged out due to inactivity or expired credentials. Please log back in to continue.",
    makeDialogBody(resp, success): Promise<Widget> {
        return import("Everlaw/LoginDialog").then((LoginDialog) => {
            enableLogoutPoll(false);
            return new LoginDialog.LoginForm({
                onLogin: () => {
                    enableLogoutPoll(true);
                    success();
                },
            });
        });
    },
};
const federalTermsOfUsePrompter: ReauthParams = {
    authMessage: NEED_FEDERAL_TERMS_OF_USE_ACK,
    dialogTitle: "Terms Of Use",
    dialogPrompt: "",
    makeDialogBody(resp, success): Promise<Widget> {
        return import("Everlaw/FederalTermsOfUse").then((FederalTermsOfUse) => {
            return new FederalTermsOfUse.TermsOfUseWidget({
                onAgreement: success,
            });
        });
    },
};
const mfaPrompter: ReauthParams = {
    authMessage: NEED_MFA,
    dialogTitle: "Authentication required",
    dialogPrompt: "",
    makeDialogBody(resp: Response, success): Promise<Widget> {
        const hasDevice: boolean = resp.data;
        return import("Everlaw/MFA").then((MFA) => {
            if (!hasDevice && !document.hidden) {
                // Make sure the user gets an email code if they don't have a device (and don't have
                // a recent token)
                MFA.sendEmailCode(true);
            }
            return new MFA.AuthPrompt({
                hasDevice: hasDevice,
                onAuthenticate: success,
                fromPrompt: true,
            });
        });
    },
};
let currPrompter: ReauthPrompter;
const reauthPrompters: ReauthParams[] = [loginPrompter, federalTermsOfUsePrompter, mfaPrompter];

// Need to set the promptIfNeeded function on the Rest module here so that it can be called from
// Rest.ts without requiring an import.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
Rest.promptIfNeeded = function (res: Response, retry: () => void, fail: () => void): boolean {
    let toPrompt: ReauthParams | null = null;
    for (const reauthPrompt of reauthPrompters) {
        if (reauthPrompt.authMessage === res.message) {
            toPrompt = reauthPrompt;
            break;
        }
    }
    if (res.message === NEED_TERMS_OF_USE_ACK) {
        // Special case for users that haven't signed the latest major version of terms (not to be
        // confused with the federal terms of use). Should redirect to termsOfUse.do instead.
        if (onSuperuserPage()) {
            return true;
        }
        const newLocation =
            res.data && res.data.target
                ? "/termsOfUse/termsOfUse.do?t=" + res.data.target
                : "/termsOfUse/termsOfUse.do";
        location.replace(newLocation);
    }
    if (!toPrompt) {
        // The error did not match any of our prompters.
        return false;
    }
    if (
        currPrompter
        && currPrompter.isActive()
        && currPrompter.authMessage === toPrompt.authMessage
    ) {
        // There is already a prompt for the same issue. Leave it open and reject this new request.
        return false;
    }
    if (currPrompter) {
        currPrompter.destroy();
    }
    currPrompter = new ReauthPrompter(toPrompt, retry, fail, res);

    // Purge the screen of recommendations.
    const rec = getActiveRecommendation();
    const curStep = rec?.recommendationChain.currentIdx;
    if (rec && curStep !== undefined && curStep >= 0) {
        rec?.getStep(curStep).deactivate();
    }
    return true;
};
