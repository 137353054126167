import Button = require("Everlaw/UI/Button");
import Checkbox = require("Everlaw/UI/Checkbox");
import Dialog = require("Everlaw/UI/Dialog");
import Dom = require("Everlaw/Dom");
import Input = require("Everlaw/Input");
import Is = require("Everlaw/Core/Is");
import UI = require("Everlaw/UI");
import UI_ValidatedSubmit = require("Everlaw/UI/ValidatedSubmit");
import Util = require("Everlaw/Util");

import baseWindow = require("dojo/_base/window");
import dojo_on = require("dojo/on");
import { EVERCLASS, everClassProp } from "Everlaw/EverAttribute/EverClass";
import { EverId } from "Everlaw/EverAttribute/EverId";

class QueryDialog {
    // prompt, onSubmit, and onCancel can be updated before each show;
    // the others are fixed
    submitText: string;
    cancelText: string;
    // Set to false if the submit action is potentially dangerous, e.g. deletion. If this is set to
    // false, submitIsWarn will be used to determine the severity of the alerting class. A submitIsWarn
    // value of true will result in the warning styles whereas a value of true will result in the
    // alert styles.
    // Defaults to true
    submitIsSafe?: boolean;
    // If true, and submitIsSafe is false, then use the warning styles rather than alert styles
    // Defaults to false
    submitIsWarn?: boolean;
    submitButtonParams: Button.Params;
    cancelButtonParams: Button.Params;
    prompt: Dom.Content;
    title: string;
    style: Dom.StyleProps;
    body: Dom.Nodeable;
    // the type of node to create to hold the prompt
    promptType: string;
    // Should we destroy the dialog when it gets closed? If true, this is effectively a "single-use"
    // dialog. If set to false, you must manually call destroy() to clean the dialog up.
    destroyOnClose: boolean;
    refocus: boolean;
    _dialog: Dialog;
    _toDestroy: Util.Destroyable[] = [];
    _submitButton: Button;
    // Every query dialog will use the ValidatedSubmit widget but if no forms are provided via
    // params.forms then the widget will operate just like a submit button.
    accessibleSubmit: UI_ValidatedSubmit.ValidatedSubmit;
    cancelButton: HTMLButtonElement;
    lowerLeftContent: Dom.Content;
    lowerLeftStyle: Dom.StyleProps;
    lowerDiv: HTMLElement;
    private lowerLeftDiv: HTMLElement;

    onSubmit(queryDialog: unknown, ...args: unknown[]) {
        return true;
    }
    onCancel(queryDialog: unknown, ...args: unknown[]) {
        return true;
    }
    onHide() {}
    onShow() {
        // for custom onShow behavior
        return;
    }
    afterHide(submit?: boolean) {
        // Generally you should be using onSubmit and onCancel, but if you need something to happen
        // after the dialog hides, say, for focusing reasons, use this.
        return;
    }
    private _content: HTMLElement;
    private _prompt: HTMLElement;
    _buttonDiv: HTMLElement;
    private _bodyPlaced: boolean;
    constructor(params: QueryDialog.Params) {
        Object.assign(this, params);
        if (!Is.defined(params.closable)) {
            params.closable = true;
        }
        this._content = Dom.div(everClassProp(EVERCLASS.QUERY_DIALOG));
        this._prompt = Dom.create(this.promptType, null, this._content);
        this.lowerLeftDiv = Dom.div({ class: "dialog-lower-left-content" }, this.lowerLeftContent);
        if (this.lowerLeftStyle) {
            Dom.style(this.lowerLeftDiv, this.lowerLeftStyle);
        }
        this.lowerDiv = Dom.place(
            Dom.div({ class: "dialog-lower-div" }, this.lowerLeftDiv),
            this._content,
        );
        if (params.lowerDivClass) {
            this.lowerDiv.classList.add(params.lowerDivClass);
        }
        this._buttonDiv = Dom.create("div", { class: "dialog-buttons no-padding" }, this.lowerDiv);
        if (this.cancelText) {
            const cancel = new Button(
                Object.assign(
                    {
                        label: this.cancelText,
                        onClick: this._cancelWrapper.bind(this),
                        parent: this._buttonDiv,
                        class: "generic button query-cancel",
                        makeFocusable: true,
                    },
                    this.cancelButtonParams,
                ),
            );
            this.cancelButton = cancel.node;
            this._toDestroy.push(cancel);
        }
        this.accessibleSubmit = new UI_ValidatedSubmit.ValidatedSubmit(
            Object.assign(
                {
                    forms: params.forms,
                    buttonParams: Object.assign(
                        {
                            label: this.submitText || "Submit",
                            onClick: this.submit.bind(this),
                            parent: this._buttonDiv,
                            class:
                                "important button query-submit "
                                + (this.submitIsSafe
                                    ? "safe"
                                    : this.submitIsWarn
                                      ? "warning"
                                      : "unsafe"),
                            makeFocusable: true,
                            focusDivPos: "after",
                            everId: params.submitEverId,
                        },
                        this.submitButtonParams,
                    ),
                },
                params.extraSubmitParams,
            ),
        );
        this._toDestroy.push(this.accessibleSubmit);
        this._submitButton = this.accessibleSubmit.getSubmitButton();
        if (params.confirmationContent) {
            this.disableSubmit();
            const checkbox = new Checkbox({
                state: false,
                label: params.confirmationContent,
                onChange: (state: boolean) => {
                    this.disableSubmit(!state);
                },
            });
            this._toDestroy.push(checkbox);
            Dom.place(
                Dom.div({ style: "margin-top: 16px" }, Dom.node(checkbox)),
                this.lowerDiv,
                "before",
            );
        }
        Dom.place(this._content, baseWindow.body());
        // don't reposition for iOS because virtual keyboard displaces
        // and makes it reposition
        this._dialog = new Dialog({
            title: this.title,
            content: this._content,
            style: this.style,
            onCancel: this._cancelWrapper.bind(this),
            onHide: () => {
                // Hiding requires an animation, so we can't destroy until the dialog is hidden
                if (this.destroyOnClose) {
                    this.destroy();
                }
                this.onHide();
            },
            onResize: params.onResize,
            refocus: params.refocus,
            autofocus: params.autofocus,
            closable: params.closable,
            classes: params.classes,
        });
        this._toDestroy.push(this._dialog);
    }
    show() {
        this.setContent();
        this._dialog.show();
        this.onShow();
    }
    setContent(): void {
        // By using Dom.setContent, we allow for rich (HTML-laden) prompts.
        Dom.setContent(this._prompt, this.prompt);
        if (this.body) {
            // Post-prompt content (form, input box, etc)
            if (!this._bodyPlaced) {
                Dom.place(this.body, this._prompt, "after");
                this._bodyPlaced = true; // placing body twice makes tinymce editor unusable in Firefox
            }
        }
    }
    setSubmitText(text: string) {
        this.accessibleSubmit.getSubmitButton().node.innerText = text;
    }
    hide(submit?: boolean) {
        this._dialog.hide();
        this.afterHide(submit);
    }
    isOpen() {
        return this._dialog.isOpen();
    }
    showLowerDiv() {
        Dom.show(this.lowerDiv);
    }
    hideLowerDiv() {
        Dom.hide(this.lowerDiv);
    }
    disableSubmit(state = true) {
        UI.toggleDisabled(this.accessibleSubmit, state);
    }
    disableCancel(state = true) {
        UI.toggleDisabled(this.cancelButton, state);
    }
    submit() {
        if (this.onSubmit(this)) {
            this.hide(true);
        }
    }
    _cancelWrapper() {
        if (this.onCancel(this)) {
            this.hide();
        }
    }
    destroy() {
        Util.destroy(this._toDestroy);
        this._toDestroy = [];
    }
    /**
     * Appends the given DOM node to the end of the QueryDialog, after the buttons.
     */
    append(node: Node) {
        this._content.appendChild(node);
    }
    registerDestroyable(x: Util.Destroyable) {
        this._toDestroy.push(x);
    }
    setTitle(title: HTMLHeadingElement | string): void {
        this._dialog.setTitle(title);
    }
}
QueryDialog.prototype.submitText = "Submit";
QueryDialog.prototype.cancelText = "Cancel";
QueryDialog.prototype.submitIsSafe = true;
QueryDialog.prototype.submitIsWarn = false;
QueryDialog.prototype.prompt = "Are you sure?";
QueryDialog.prototype.title = "Input required";
QueryDialog.prototype.style = {
    minWidth: "450px",
    maxWidth: "700px",
};
QueryDialog.prototype.promptType = "span";
QueryDialog.prototype.destroyOnClose = true;

/* TODO Refactor this to remove module namespace */
/* eslint-disable-next-line @typescript-eslint/no-namespace */
module QueryDialog {
    /**
     * Params used by the create() factory funtion below.
     */
    export interface Params {
        // defaults shown below
        // the title of the query dialog
        title?: string;
        // the prompt to display to the user
        prompt?: Dom.Content | ((data: any) => Dom.Content);
        // whether the submit action is safe or unsafe (e.g. deletion).
        // If this is set to false, the value of submitIsWarn will be used to determine the severity of
        // the alerting class. A submitIsWarn value of true will result in the warning styles whereas a
        // value of true will result in the alert styles.
        // defaults to true
        submitIsSafe?: boolean;
        // if true, and submitIsSafe is false, then use the warning styles, otherwise, use the alert styles.
        // defaults to false
        submitIsWarn?: boolean;
        // The EverId to apply to the submit button.
        submitEverId?: EverId;
        // a function(queryDialog) that executes when the user elects to submit.
        // return true to close the query dialog and false to keep it open (often
        // used for invalid inputs)
        onSubmit?: (queryDialog?: QueryDialog) => boolean | void;
        // a function that executes when the resize() is called on the dialog box.
        onResize?: (dialog?: Dialog) => void;
        // a function(queryDialog) that executes when the user elects to cancel
        // return true to close the query dialog and false to keep it open
        onCancel?: (queryDialog?: QueryDialog) => boolean | void;
        onHide?: () => void;
        // a function that executes when the dialog is shown
        onShow?: () => void;
        // the text in the submit option (defaults to "Submit")
        submitText?: string;
        // the text in the cancel option (defaults to "Cancel")
        cancelText?: string;
        // a function to call when the underlay is clicked
        onUnderlayClick?: () => void;
        // Should we destroy the dialog when it gets closed? If true, this is effectively a "single-use"
        // dialog. If set to false, you must manually call destroy() to clean the dialog up.
        // true by deafult.
        destroyOnClose?: boolean;
        // body goes after the prompt. Some usages have the prompt simply be info
        // and the body be the input elements. Other usages use only the prompt
        body?: Dom.Nodeable | DocumentFragment;
        // the type of node to create to hold the prompt
        promptType?: string;
        refocus?: boolean;
        autofocus?: boolean;
        submitButtonParams?: Button.Params;
        cancelButtonParams?: Button.Params;
        classes?: string | string[];
        style?: Dom.StyleProps;
        // If defined and false, make the background opaque and remove the close button in the upper
        // right of the dialog.
        closable?: boolean;
        lowerLeftContent?: Dom.Content;
        lowerLeftStyle?: Dom.StyleProps;
        lowerDivClass?: string;
        // If defined, a confirmation checkbox will be created with this content.
        confirmationContent?: Dom.Content;
        // This is for any other arguments the ValidatedSubmit widget might take that are rarely used.
        extraSubmitParams?: any;
        // The validated inputs that couple with the validated submit widget.
        forms?: UI_ValidatedSubmit.ValidatedSubmitForm[];
    }

    /**
     * Displays a yes/no dialog to the user, executing the indicated onSubmit/onCancel methods when the
     * user makes the selection.
     *
     * @params see QueryDialogParams, and check out QueryDialog for more information
     */
    export function create(params: Params, autoShow = true) {
        // These lines protect onSubmit and onCancel callbacks from erroneously being passed the
        // QueryDialog object where a partially applied function has optional (often callback)
        // arguments. The alternatives are to (1) avoid using partially applied funtion; (2) change
        // QueryDialog to not pass itself to the callback, a non-starter if existing code uses it;
        // or (3) use partially applied functions but pass null arguments for all the callbacks. The
        // approach used here seems to be the least error-prone.
        const onSubmit = params.onSubmit;
        params.onSubmit = function (queryDialog?: QueryDialog) {
            return onSubmit ? onSubmit(queryDialog) : true;
        };
        const onCancel = params.onCancel;
        params.onCancel = function (queryDialog?: QueryDialog) {
            return onCancel ? onCancel(queryDialog) : true;
        };
        params.destroyOnClose === null && (params.destroyOnClose = true);
        // Now create the dialog with our slightly-modified params.
        const dialog = new QueryDialog(params);
        autoShow && dialog.show();
        if (params.onUnderlayClick) {
            dialog.registerDestroyable(
                dojo_on(Dom.node(dijit._underlay), Input.tap, () => params.onUnderlayClick?.()),
            );
        }
        if (Is.defined(params.closable) && !params.closable) {
            Dom.addClass(Dom.node(dijit._underlay), "opaque");
        }
        return dialog;
    }
}

export = QueryDialog;
