/**
 * Functions that operate on Arrays.
 */
import Core = require("Everlaw/Core");
import Cmp = require("Everlaw/Core/Cmp");
import Is = require("Everlaw/Core/Is");
import { ArrayUtil } from "design-system";
import { getCounts } from "Everlaw/Core/MapUtil";

export const { wrap, filterNonNullish } = ArrayUtil;

interface ArrayLikeTest<T> {
    (elem: T, i: number, arr: ArrayLike<T>): boolean;
}

/**
 * Returns true iff the given array contains the given elt according to the given equality function,
 * which defaults to Core.equals.
 */
export function contains<T>(arr: ArrayLike<T>, elt: T, eq = Core.equals): boolean {
    return indexOf(arr, elt, eq) >= 0;
}

/**
 * Returns the first index i in arr for which the given test returns a truthy value. If no call to
 * test returns true, then -1 is returned.
 */
export function first<T>(arr: ArrayLike<T>, test: ArrayLikeTest<T>, startIndex = 0): number {
    for (let i = startIndex; i < arr.length; i++) {
        if (test(arr[i], i, arr)) {
            return i;
        }
    }
    return -1;
}

/**
 * Returns the first element in arr for which the given test returns a truthy value. If no call to
 * test returns true, then null is returned.
 */
export function firstElement<T>(
    arr: ArrayLike<T>,
    test: ArrayLikeTest<T>,
    startIndex = 0,
): T | null {
    const idx = first(arr, test, startIndex);
    if (idx === -1) {
        return null;
    }
    return arr[idx];
}

/**
 * Returns the index of elt in the given array according to the given equality function, which
 * defaults to Core.equals. If elt is not in the array, then -1 is returned.
 */
export function indexOf<T>(
    arr: ArrayLike<T>,
    elt: T,
    eq: (x: T, y: T) => boolean = Core.equals,
): number {
    return first(arr, (e) => eq(elt, e));
}

/**
 * Returns an array with each element separated by the given separator. If a function is provided,
 * uses that function to compute the separator at the given index. Leaves original array intact.
 * @param arr Array of elements to join
 * @param separator A separator element to place between the given elements, or a function of type
 * (number) => any which computes the separator at a given index
 */
export function join<T, S>(arr: ArrayLike<T>, separator: S | ((index: number) => S)): (T | S)[] {
    if (!Is.func(separator)) {
        return join(arr, () => separator);
    }
    const ret: (T | S)[] = [];
    for (let i = 0; i < arr.length - 1; i++) {
        ret.push(arr[i]);
        ret.push(separator(i));
    }
    if (arr.length > 0) {
        ret.push(arr[arr.length - 1]);
    }
    return ret;
}

/**
 * Returns the element of arr that has the highest value according to toNum, or undefined if arr is
 * empty. If there is a tie, the first element is used. The toNum call is performed once per
 * element.
 */
export function max<T>(arr: T[], toNum: (t: T) => number): T | null {
    let maxT: T | null = null;
    let maxNum = -Infinity;
    arr.forEach((t) => {
        const n = toNum(t);
        if (n > maxNum) {
            maxT = t;
            maxNum = n;
        }
    });
    return maxT;
}

export function min<T>(arr: T[], toNum: (t: T) => number): T | null {
    return max(arr, (t: T) => -toNum(t));
}

export function sum<T>(arr: T[], toNum: (t: T) => number): number {
    return arr.reduce((a, b) => a + toNum(b), 0);
}

export function average<T>(arr: T[], toNum: (t: T) => number): number {
    if (arr.length === 0) {
        return 0;
    }
    return sum(arr, toNum) / arr.length;
}

export function fromArrayLike<T>(arrayLike: ArrayLike<T>): T[] {
    const array: T[] = [];
    for (let i = 0; i < arrayLike.length; i++) {
        array.push(arrayLike[i]);
    }
    return array;
}

interface TreeArray<T> extends Array<T | TreeArray<T>> {}

/**
 * Returns a flat array of all of the given arguments. For any array argument, the returned array
 * will contain its elements in place of the array.
 */
export function flat<T>(...items: Array<T | TreeArray<T>>): T[] {
    const flat: T[] = [];
    addElementsToFlat(items, flat);
    return flat;
}

function addElementsToFlat<T>(arrLike: TreeArray<T>, flat: T[]): void {
    for (const e of arrLike) {
        if (Is.array(e)) {
            addElementsToFlat(e, flat);
        } else {
            flat.push(e);
        }
    }
}

/**
 * Calls zipper on successive pairs of aT and aU, stopping iteration at the end of the shortest
 * array.
 */
export function zipped<T, U>(aT: T[], aU: U[], zip: (t: T, u: U, i: number) => void): void {
    for (let i = 0, len = Math.min(aT.length, aU.length); i < len; i++) {
        zip(aT[i], aU[i], i);
    }
}

/**
 * Implementation of Python's zip() builtin for two sequences that returns a list
 * of tuples, where the i-th element is the i-th element from each of the input sequences.
 *
 * NB: Python's implementation can zip an arbitrary number of sequences, whereas this
 * only supports two!
 */
export function zip<T, U>(aT: T[], aU: U[]): [T, U][] {
    const ret: [T, U][] = [];
    zipped(aT, aU, (t, u) => {
        ret.push([t, u]);
    });
    return ret;
}

/**
 * Partitions an array over a predicate, returning a tuple of the matching and non-matching arrays.
 */
export function partition<T>(arr: T[], isGood: (val: T) => boolean): [T[], T[]] {
    const good: T[] = [];
    const bad: T[] = [];
    arr.forEach((a) => {
        if (isGood(a)) {
            good.push(a);
        } else {
            bad.push(a);
        }
    });
    return [good, bad];
}

/**
 * Removes the first element of arr that matches elt according to Core.equals. Returns true iff an
 * element was removed.
 */
export function remove<T>(arr: T[], element: T): boolean {
    return removeIf(arr, (e) => Core.equals(element, e));
}

/**
 * Removes the first element of arr for which the given test returns true. Returns true iff an
 * element was removed.
 */
export function removeIf<T>(
    arr: T[],
    test: (element: T, i: number, arr: ArrayLike<T>) => boolean,
): boolean {
    const idx = first(arr, test);
    if (idx < 0) {
        return false;
    }
    arr.splice(idx, 1);
    return true;
}

/**
 * Returns an array of length count where each element of the array is item.
 */
export function repeat<T>(item: T, count: number): T[] {
    const array: T[] = [];
    for (let i = 0; i < count; i++) {
        array.push(item);
    }
    return array;
}

/**
 * Useful for slicing Array-like objects that aren't actually Arrays (e.g., NodeList, Arguments).
 * The begin and end parameters are both optional. Note that the returned Array is a copy.
 *
 * Examples:
 *
 * let a = [0, 1, 2, 3, 4];
 *
 * Because a is a true Array, we can do:
 *
 * - a.slice(1) -> [1, 2, 3, 4]
 * - a.slice(1, 3) -> [1, 2]
 * - a.slice() -> [0, 1, 2, 3, 4]
 *
 * Even if a were Array-like but not an Array, this function will work the same way:
 *
 * - Arr.slice(a, 1) -> [1, 2, 3, 4]
 * - Arr.slice(a, 1, 3) -> [1, 2]
 * - Arr.slice(a) -> [0, 1, 2, 3, 4] (useful for converting a non-Array to an Array)
 */
export function slice<T>(arrLike: ArrayLike<T>, begin?: number, end?: number): T[] {
    return Array.prototype.slice.call(arrLike, begin, end);
}

interface SortCmpParams<T> {
    cmp: Cmp<T>;
}

interface SortKeyParams<T> {
    key: (val: T) => Cmp.Full;
}

/**
 * Sorts and returns arr using optional params:
 *  - cmp: a standard cmp callback that accepts two items from arr; default is Cmp.full
 *  - key: used to convert each element before it is passed to Cmp.full; must return values with a
 *      consistent type (refer to Has.key)
 *
 * This modifies arr in place! If you do not want that behavior, use Arr.sorted instead.
 */
export function sort<T extends Cmp.Full>(arr: T[]): T[];
export function sort<T>(arr: T[], params: SortCmpParams<T>): T[];
export function sort<T>(arr: T[], params: SortKeyParams<T>): T[];
export function sort<T>(arr: T[], params?: Partial<SortCmpParams<T> & SortKeyParams<T>>): T[] {
    if (params?.cmp) {
        return arr.sort(params.cmp);
    } else if (params?.key) {
        return arr.sort(Cmp.forKey(Cmp.full, params.key));
    } else {
        return (arr as Cmp.Full[]).sort(Cmp.full) as T[];
    }
}

/**
 * Operates just like Arr.sort except that arr is first copied before it is sorted and returned.
 * This means that Arr.sorted((arr = [3, 2, 1])) returns [1, 2, 3], but arr is left as [3, 2, 1].
 */
export function sorted<T extends Cmp.Full>(arr: T[]): T[];
export function sorted<T>(arr: T[], params: { cmp: Cmp<T> }): T[];
export function sorted<T>(arr: T[], params: { key: (val: T) => Cmp.Full }): T[];
export function sorted<T>(arr: T[], params?: Partial<SortCmpParams<T> & SortKeyParams<T>>): T[] {
    // Casting here should be unnecessary, since the base implementation uses the same type for
    // params, but the base method is not actually callable (only the overloads are). So, we
    // have to make this dumb cast.
    return sort(arr.slice(0), params as never);
}

/**
 * Returns a new array that contains only the unique elements of arr, according to Core.hash and
 * Core.equals. The order of the original elements is preserved.
 */
export function unique<T>(arr: T[]): T[] {
    const hashed: Record<string, T[]> = {};
    const uniq: T[] = [];
    arr.forEach((o) => {
        const hash = Core.hash(o);
        const hashMatches = hashed[hash];
        if (hashMatches) {
            // We already have a value for this hash. Check for collisions.
            if (!hashMatches.some((m) => Core.equals(m, o))) {
                hashMatches.push(o);
                uniq.push(o);
            }
        } else {
            hashed[hash] = [o];
            uniq.push(o);
        }
    });
    return uniq;
}

export function zeroes(len: number): number[] {
    return repeat(0, len);
}

/**
 * Returns the index of val in arr. When val is not in arr, a negative value n is returned such that
 * (-n - 1) is the index where val would have been if it were in arr.
 *
 * The comparison function cmp is called with val as the first argument and individual elements of
 * arr as the second argument. It must return a standard cmp value. If cmp is not provided, Cmp.full
 * is used instead.
 *
 * In order for the binary search to work correctly, arr must be sorted according to cmp. In order
 * for Arr.contains(arr, val, eq) to be true when Arr.binarySearch(arr, val, cmp) returns a non-
 * negative number, cmp(x, y) === 0 must be true iff eq(x, y) === true. This is typically the case
 * for Cmp.full and Core.equals, provided that any `compare` and `equals` methods are implemented
 * consistently.
 */
export function binarySearch<T extends Cmp.Full>(arr: T[], val: T): number;
export function binarySearch<T, U>(arr: T[], val: U, cmp: Cmp<U, T>): number;
export function binarySearch<T, U>(
    arr: T[],
    val: U,
    cmp: Cmp<U, T> = Cmp.full as Cmp<U, T>,
): number {
    if (!Is.defined(arr) || arr.length === 0) {
        return -1;
    }
    let start = 0;
    let end = arr.length - 1;
    while (start <= end) {
        const pivot = (start + end) >> 1;
        const res = cmp(val, arr[pivot]);
        if (res > 0) {
            start = pivot + 1;
        } else if (res < 0) {
            end = pivot - 1;
        } else {
            return pivot;
        }
    }
    return -(start + 1); // not found
}

/**
 * Returns the index i such that test(arr[i]) is false for all elements with index < i and true for
 * all elements with index >= i, assuming that such an i exists.
 *
 * For example: Arr.binarySplit([2, 5, 7, 10, 15], function(n) { return n > 8; }) returns 3.
 */
export function binarySplit<T>(arr: T[], pred: (val: T) => boolean): number {
    // Binary search without the possibility of an actual match. The "comparator" intentionally
    // never returns 0, because we are looking for slice bounds, not an individual hit.
    return ~binarySearch(arr, null, (_, val) => (pred(val) ? -1 : 1));
}

/**
 * Inserts the given `obj` into `arr` (which is assumed to be sorted according to `Cmp.full`) in its
 * sorted position.
 *
 * Returns the index where the obj was inserted. If `insertRepeats` is false, returns -1 when an
 * object with equal comparison is already in `arr`.
 */
export function insertSorted<T extends Cmp.Full>(
    arr: T[],
    obj: T,
    cmp: (a: T, b: T) => number = Cmp.full,
    insertRepeats = false,
): number {
    const where = binarySearch(arr, obj, cmp);
    if (where >= 0 && !insertRepeats) {
        return -1;
    }
    const insertionIndex = where >= 0 ? where : -where - 1;
    arr.splice(insertionIndex, 0, obj);
    return insertionIndex;
}

/**
 * Merges two sorted lists in sorted order and returns the result.
 *
 * For example: Arr.mergeSorted([1, 3, 5], [2, 4, 6]) returns [1, 2, 3, 4, 5, 6]
 */
export function mergeSorted<T, U>(arr1: T[], arr2: U[], cmp: Cmp<T, U>): (T | U)[] {
    arr1 = arr1 || [];
    arr2 = arr2 || [];
    const merged: (T | U)[] = [];
    let i1 = 0;
    let i2 = 0;
    let iM = 0;
    while (i1 < arr1.length && i2 < arr2.length) {
        merged[iM++] = cmp(arr1[i1], arr2[i2]) > 0 ? arr1[i1++] : arr2[i2++];
    }
    return [...merged, ...slice(arr1, i1), ...slice(arr2, i2)];
}

/**
 * Removes the given item from the given arr, which is assumed to be sorted according to Cmp.full.
 *
 * Returns the index where the item was found, or -1 if it was not in arr.
 */
export function removeSorted<T extends Cmp.Full>(array: T[], obj: T, cmp = Cmp.full): number {
    const where = binarySearch(array, obj, cmp);
    if (where >= 0) {
        array.splice(where, 1);
        return where;
    }
    return -1;
}

export function countIf<T>(arr: ArrayLike<T>, test: ArrayLikeTest<T>, startIndex = 0): number {
    let count = 0;
    for (let i = startIndex; i < arr.length; i++) {
        if (test(arr[i], i, arr)) {
            count++;
        }
    }
    return count;
}

export function mapped<T, V>(
    arr: T[],
    getKey: (t: T) => string | number | null | undefined,
    getValue: (t: T) => V,
): Record<string | number, V> {
    const map: Record<string | number, V> = {};
    arr.forEach((t) => {
        const key = getKey(t);
        if (key != null) {
            map[key] = getValue(t);
        }
    });
    return map;
}

export function map<T, U>(
    iterable: { forEach: (callback: (t: T) => void) => void },
    mappingFunc: (t: T) => U,
): U[] {
    const retArray: U[] = [];
    iterable.forEach((t) => retArray.push(mappingFunc(t)));
    return retArray;
}

/**
 * Given an array, return an object where:
 * - the values are array elements
 * - the keys are array elements passed through a getKey function.
 *
 * This is equivalent to { getKey(x): x for x in arr } in Python.
 */
export function keyBy<V>(arr: V[], getKey: (x: V) => string): Record<string, V> {
    const result: Record<string, V> = {};
    arr.forEach((x) => {
        result[getKey(x)] = x;
    });
    return result;
}

/**
 * Given an array, return an object where:
 * - the keys are array elements passed through a getKey function.
 * - the values are array elements grouped by their keys
 */
export function groupBy<V>(arr: V[], getKey: (x: V) => string | number): Record<string, V[]> {
    const result: Record<string, V[]> = {};
    arr.forEach((x) => {
        const k = getKey(x);
        if (k in result) {
            result[k].push(x);
        } else {
            result[k] = [x];
        }
    });
    return result;
}

// TODO IE support dropped, no longer necessary
/**
 * IE doesn't support iterator-related methods on sets yet, so this mimics 'new Set(array)'...
 */
export function toSet<T>(arrayLike: ArrayLike<T>): Set<T> {
    const set = new Set<T>();
    for (let i = 0; i < arrayLike.length; i++) {
        set.add(arrayLike[i]);
    }
    return set;
}

export function toMap<T, K, V>(
    arr: T[],
    getKey: (t: T) => K | undefined | null,
    getValue: (t: T) => V,
): Map<K, V> {
    const map = new Map<K, V>();
    arr.forEach((t) => {
        const key = getKey(t);
        if (key != null) {
            map.set(key, getValue(t));
        }
    });
    return map;
}

export function equals<V>(arr1: V[], arr2: V[]): boolean {
    if (arr1 === arr2) {
        return true;
    } else if (!arr1 || !arr2 || arr1.length !== arr2.length) {
        return false;
    }
    return arr1.every((a, i) => a === arr2[i]);
}

/**
 * For every unique element in given array arr, calls given function f. Operates in order on the
 * array, so only the first unique instance of any element in the given array will be passed to the
 * given function.
 */
export function forEachOrderedUniqueElement<V>(arr: V[], f: (v: V) => void): void {
    Array.from(arr).sort().forEach(f);
}

/**
 * Just like Array.concat, but allows passing undefined or null for both parameters.
 */
export function concat<V>(arr1: V[] | undefined | null, arr2: V[] | undefined | null): V[] {
    return (arr1 || []).concat(arr2 || []);
}

/**
 * Given an array, returns a record where the keys of the record are the values of the array, and
 * the values of the record are the indexes those values occur at.
 *
 * Note that if a value occurs multiple times in the given array, the last index is used.
 */
export function toIndexMap(arr: string[]): Record<string, number> {
    const result: Record<string, number> = {};
    arr.forEach((x, i) => {
        result[x] = i;
    });
    return result;
}

/**
 * Utility method to check if the contents of the arrays are equal, ignoring order.
 */
export function arrayContentsEqual(arr1: string[], arr2: string[]) {
    if (arr1 === arr2) {
        return true;
    }

    if (arr1.length !== arr2.length) {
        return false;
    }

    const arr1Contents = getCounts(arr1);
    const arr2Contents = getCounts(arr2);

    if (arr1Contents.size !== arr2Contents.size) {
        return false;
    }

    for (const element of arr1Contents.keys()) {
        if (arr1Contents.get(element) !== arr2Contents.get(element)) {
            return false;
        }
    }

    return true;
}
