/**
 * The various ways we alert developers when an N+1 query is detected.
 */
export enum Alert {
    /**
     * This should indicate we never received any repeated query stats from the backend.
     */
    NONE = "NONE",
    /**
     * We'll dispatch a `repeatedQuery` {@link CustomEvent} to the global {@link window} object.
     *
     * This allows us to react to an N+1 query being detected. For example, Cypress can listen
     * for this event, and fail a test when the event is fired.
     */
    BROWSER_EVENT = "BROWSER_EVENT",
    /**
     * This builds off {@link #BROWSER_EVENT}, and additionally warns the user of an N+1
     * query via a dialog.
     */
    DIALOG = "DIALOG",
}
