export const NEED_AUTH = "_needAuth";
export const NEED_FEDERAL_TERMS_OF_USE_ACK = "_needFederalTermsOfUseAck";
export const NEED_MFA = "_needMFA";

// For this case we redirect users instead of showing an auth prompt.
export const NEED_TERMS_OF_USE_ACK = "_needTermsOfUseAck";

// Auth messages that should be handled by an auth prompter.
export const AUTH_PROMPT_MESSAGES = [NEED_AUTH, NEED_FEDERAL_TERMS_OF_USE_ACK, NEED_MFA] as const;

// Auth message that should be handled by an auth prompter.
export type AuthPromptMessage = (typeof AUTH_PROMPT_MESSAGES)[number];
