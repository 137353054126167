// Import this module for its side effects. This needs to run once before any other Rest call and
// will ensure that reauth prompters are set up.
import "Everlaw/Rest/RestAuthInit";

import Dom = require("Everlaw/Dom");
import baseWindow = require("dojo/_base/window");
import { handleRepeatedQuery } from "Everlaw/Rest";

// dojo's multipleDefine errors don't create a bugsnag error. This explicitly sends multipleDefine
// errors along with appropriate debugging info to bugsnag.
require.on("error", (e) => {
    if (e.message === "multipleDefine") {
        BugsnagClient.notify(e, (event) => {
            event.addMetadata("Custom", { url: e.info.url, id: e.info.mid });
        });
    }
});

Dom.addClass(baseWindow.body(), "evertheme");
const viewport = Dom.byId("viewport-info");
// Should catch iPad minis, etc but not smartphones or other smaller devices.
if (viewport && window.innerWidth < 960 && window.innerWidth > 600) {
    Dom.setAttr(viewport, "content", "width=960");
}

if (window.postLoadStats) {
    const name = "Page loaded: " + location.pathname;
    const stats = window.postLoadStats;
    handleRepeatedQuery(stats.repeatedQueries, stats.alertLevel);
    console.log(name + " (execute time: " + stats.executeTime + "ms)");
    console.log(stats.message);
}
