/**
 * Defines core functionality based on the implicit interfaces defined in Core/Has. The functions
 * here operate on all values, but their behavior is affected by the presence of those interfaces.
 *
 * Core.ts and the libraries defined in Core/ are designed to be used by both Web Workers and by our
 * entire codebase. Web Workers have no UI (no global window object), and they should not pull in UI
 * code. See Core/README for more information.
 *
 * For this reason, Core.ts and all libraries in Core/ should only depend on other Core/ libraries.
 * If a library is suitable for use by Core, then it should be stored in Core/.
 *
 * TODO: Remove the Bugsnag import, or if Bugsnag works in Web Workers, move it to Core/Bugsnag.
 * TODO: Remove react and design-system imports from Core.ts, which is used by Core/Arr.
 *
 * For now, no Web Workers use Core/Arr or Core.ts, so these are not currently critical TODOs.
 */
import Bugsnag = require("Everlaw/Bugsnag");
import Has = require("Everlaw/Core/Has");
import Is = require("Everlaw/Core/Is");
import { useEffect, useState } from "react";
import { useLatest } from "design-system";

/**
 * A type representing an element of Json.
 * It can be a primitive,or an array or object built from other JSONValue
 * (similar to the com.google.gson.JsonElement used server-side).
 * The typical use is to convert an object to this type before passing to the server.
 */
export type JSONValue =
    | string
    | number
    | boolean
    | null
    | JSONValue[]
    | { [key: string]: JSONValue };

export interface Serializable {
    toJSON: () => JSONValue;
}

/**
 * Returns true iff x and y are equal according to ===, Has.eq, Has.id, or Has.cmp. For arrays, a
 * deep, element-wise equality check is performed.
 * If x and y are objects, and they do not implement standard equals methods, and the parameter isObject
 * is set to true, every property of x and y will be recursively checked for equality. For typed
 * objects it is generally preferable to implement an equals method, as some properties may not be
 * relevant to equality, but this type of check may be appropriate for deserialized json-like objects
 * such as those of type {@link JSONValue}.
 * @param isObject : boolean value set to true if x and y are objects
 */
export function equals<T>(x: T, y: T, isObject?: boolean): boolean;
export function equals(x: any, y: any, isObject = false): boolean {
    if (x === y) {
        return true;
    }
    if (Is.array(x) && Is.array(y)) {
        const xl = x.length;
        if (xl !== y.length) {
            return false;
        }
        for (let i = 0; i < xl; i++) {
            if (!equals(x[i], y[i], isObject)) {
                return false;
            }
        }
        return true;
    }
    if (Is.sameClass(x, y)) {
        if (Has.eq(x)) {
            return x.equals(y);
        }
        if (Has.id(x)) {
            return x.id === y.id;
        }
        if (Has.cmp(x)) {
            return x.compare(y) === 0;
        }
    }

    if (isObject) {
        if (!(Is.object(x) && Is.object(y))) {
            return false;
        }

        const xKeys = new Set(Object.keys(x));
        const yKeys = new Set(Object.keys(y));

        if (xKeys.size !== yKeys.size) {
            return false;
        }

        for (const key of xKeys) {
            if (!yKeys.has(key) || !equals(x[key], y[key], isObject)) {
                return false;
            }
        }
        return true;
    }

    return false;
}

/**
 * Converts o, which may be any value, to a String suitable for use as an Object key by using the
 * Has.hash and Has.id interfaces. If neither of those is implemented, then String(o) is used
 * instead.
 *
 * The string "__proto__" is never returned, since that cannot safely be stored in an Object.
 *
 * When hashing objects for general usage, it's important to handle collisions appropriately. When
 * two different values x and y hash to the same key, they may not be equal, so a subsequent call to
 * Core.equals(x, y) should be performed.
 *
 * If you know that you are hashing an object that Has.hash, then there is no need to call
 * Core.hash; you can simply use o.hash() directly.
 */
export function hash(o: any) {
    // We'll just stringify the object itself, as there's not much else we can do.
    const hash = Has.hash(o) ? o.hash() : Has.id(o) ? o.id : o;
    const asStr = String(hash);
    return asStr === "__proto__" ? "__proto__escaped" : asStr;
}

/**
 * The callback function for a {@link Channel} subscription.
 */
export type ChannelCallback<U> = (update: U) => void;

/**
 * A type-safe pub/sub channel. Set the type parameter to the payload of the publish() method, which
 * is also the argument passed to subscriber callbacks. Defaults to an empty object.
 */
export class Channel<U> {
    private subs: ChannelCallback<U>[] = [];
    private lastUpdate: U | null = null;

    /**
     * Subscribe to updates from the channel.
     *
     * @param callback The callback to invoke when the channel is updated.
     * @returns An unsubscribe function that should be called during cleanup
     */
    subscribe(callback: ChannelCallback<U>): () => void {
        this.subs.push(callback);
        return () => {
            const idx = this.subs.lastIndexOf(callback); // O(1) assuming LIFO
            idx >= 0 && this.subs.splice(idx, 1);
        };
    }

    /**
     * Publish an update to the channel.
     *
     * @param update the update payload
     */
    publish(update: U): void {
        // Until we have strict null checks in place, we need this runtime check as publishing
        // null or undefined to a channel can break React support (since it won't be clear from
        // lastUpdate whether the channel has ever been published to or not).
        if (update === null || update === undefined) {
            Bugsnag.notify(Error("Cannot pass null/undefined to Core.Channel#publish"));
        }
        this.lastUpdate = update;
        this.subs.forEach((sub) => sub(update));
    }

    /**
     * Get the payload from the last update. Useful if you subscribe to the channel after an update
     * has occurred. Also needed by the {@link useChannel} React hook.
     */
    getLastUpdate(): U | null {
        return this.lastUpdate;
    }

    /**
     * Unsubscribe everyone from the channel, for cleanup purposes.
     */
    unsubscribeAll(): void {
        this.subs = [];
    }

    /**
     * Returns true if there is at least one subscription to the channel.
     */
    hasSubscription(): boolean {
        return !!this.subs.length;
    }
}

/**
 * React hook for subscribing to channel updates. Whenever publish() is called on the channel, the
 * component will re-render.
 *
 * @param channel The Channel to subscribe to.
 * @returns The last published update to the channel, or null if the channel has never been used
 */
export function useChannel<U>(channel: Channel<U>): U | null {
    const initialUpdateRef = useLatest<U | null>(channel.getLastUpdate());
    const [updateWrapper, setUpdateWrapper] = useState<{ update: U | null }>({
        update: initialUpdateRef.current,
    });

    useEffect(() => {
        // Because useEffect() is run asynchronously after useChannel() has been called, we need to
        // handle the potential race condition where an update has been published to the channel in
        // between these two calls.
        const lastUpdate = channel.getLastUpdate();
        if (!Object.is(lastUpdate, initialUpdateRef.current)) {
            setUpdateWrapper({ update: lastUpdate });
        }
        return channel.subscribe((update) => setUpdateWrapper({ update }));
    }, [channel, initialUpdateRef]);

    return updateWrapper.update;
}
