/**
 * Functions that operate on Objects.
 */
import Is = require("Everlaw/Core/Is");

/**
 * Returns true iff Obj.size(obj) === 0, but returns in effectively constant time.
 */
export function empty(obj: {}) {
    return size(obj, 1) === 0;
}

/**
 * Returns the length of an Object, which is defined as the count of key-value pairs. This counts
 * only keys defined locally to the given object, not prototype-inherited keys.
 *
 * If max is provided and greater than 0, counting will stop once that value is reached.
 */
export function size(obj: {}, max = 0) {
    let count = 0;
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            count++;
            if (count === max) {
                return count;
            }
        }
    }
    return count;
}

// Valid value types for the map passed to objectToQuery() below.
// We sometimes pass Eql<T> as values which get coerced to a string by encodeURIComponent().
type ObjectToQueryMapValue =
    | string
    | number
    | boolean
    | { toString: () => string }
    | undefined
    | (string | number | boolean | undefined)[];

// Map type passed to objectToQuery
export type ObjectToQueryMap = { [name: string | number]: ObjectToQueryMapValue };

/**
 * Reimplements dojo/io-query's objectToQuery:
 * https://github.com/dojo/dojo/blob/185a4fb314de482a1b6b5668095b998da9c1b58f/io-query.js#L12
 *
 * Note that Javascript now has native support for this via the URLSearchParams class:
 * https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
 *
 * However, the behavior of URLSearchParams#toString is slightly different from Dojo's
 * objectToQuery() in a couple of ways that break our backend endpoints, in particular:
 *
 * - how EQL searches get converted to strings
 * - how array values with undefined elements are handled
 *
 * At some point we could investigate these differences and try to clean up the code, but it's just
 * a few lines of code here.
 */
export function objectToQuery(map: ObjectToQueryMap | undefined): string {
    const pairs: string[] = [];
    if (map) {
        Object.entries(map).forEach(([name, value]) => {
            // URLSearchParams#toString includes keys with undefined or empty array values in the
            // query string, but that breaks our endpoints so our logic (like Dojo's) filters those
            // values out.
            if (value !== null && value !== undefined) {
                const assign = encodeURIComponent(name) + "=";
                if (Is.array(value)) {
                    /*
                     * Oddly, unlike "raw" null or undefined values, null or undefined values
                     * in arrays are included by Dojo's objectToQuery, i.e.:
                     *
                     * IoQuery.objectToQuery({ foo: undefined, bar: [undefined, null] })
                     * 'bar=undefined&bar=null'
                     *
                     * We actually rely on this for native uploads for example, as
                     * newDatasetJob.rest expects [undefined] to be converted to "undefined".
                     * We therefore have to keep this behavior.
                     */
                    value.forEach((v) => pairs.push(assign + encodeURIComponent(v)));
                } else {
                    // value could be an object with a toString() method here (e.g. Eql<T>), so
                    // coerce to a string before passing to encodeURIComponent.
                    pairs.push(assign + encodeURIComponent(String(value)));
                }
            }
        });
    }
    return pairs.join("&");
}

/**
 * Reimplements dojo/io-query's queryToObject:
 * https://github.com/dojo/dojo/blob/185a4fb314de482a1b6b5668095b998da9c1b58f/io-query.js#L49
 *
 * Note that Javascript now has native support for this via the URLSearchParams class:
 * https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
 *
 * However, URLSearchParams does not return an object usable by our URLHash management code. While
 * we could massage this data and/or build an object by iterating via URLSearchParams#entries, for
 * now we just guarantee the original Dojo behavior expected by our older code.
 */
export function queryToObject(query: string | undefined): { [name: string]: string | string[] } {
    const ret: { [name: string]: string | string[] } = {};
    if (query) {
        query.split("&").forEach((item) => {
            if (!item.length) {
                return;
            }
            let name: string;
            let val: string;
            const s = item.indexOf("=");
            if (s < 0) {
                name = decodeURIComponent(item);
                val = "";
            } else {
                name = decodeURIComponent(item.slice(0, s));
                val = decodeURIComponent(item.slice(s + 1));
            }
            if (Is.string(ret[name])) {
                ret[name] = [ret[name] as string];
            }
            if (Is.array(ret[name])) {
                (ret[name] as string[]).push(val);
            } else {
                ret[name] = val;
            }
        });
    }
    return ret;
}

/**
 * Convert the object into an array where each entry is a key-value pair in the original object
 */
export function toArray<K, V>(map: Map<K, V>) {
    const arr: { key: K; value: V }[] = [];
    map.forEach((value, key) => {
        arr.push({ key: key, value: value });
    });
    return arr;
}

/**
 * Returns true if at least one element in the iterator resolves to true against the predicate.
 */
export function some<E>(iterator: Iterator<E>, predicate: (value: E) => boolean): boolean {
    for (let elem = iterator.next(); !elem.done; elem = iterator.next()) {
        if (predicate(elem.value)) {
            return true;
        }
    }
    return false;
}
